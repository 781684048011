import * as React from "react";
import { useState } from "react";
import TemplatePage1 from "@components/TemplatePage/TemplatePage";
import styled from "styled-components";
import Courses from "@datasources/Courses";
import People from "@datasources/People";
import { Link } from "gatsby";
import PeoplePictureLib from "@datasources/PeoplePictures";
import globe from "@images/webinars/modal/globe-svgrepo-com.svg";
import calendar from "@images/webinars/modal/calendar-svgrepo-com.svg";
import watch from "@images/webinars/modal/watch-svgrepo-com.svg";

const Wrapper = styled.div`
  padding-top: 1rem;
  position: relative;
  color: white;
`;

const CardContainer = styled.div`
  float: right;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  @media (min-width: 1024px) {
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: nowrap;
  }
`;

const Card = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  padding: 0;
  margin: 1rem 0 1rem 0;
  background-color: #8697ad;
  text-align: center;

  @media only screen and (min-width: 1024px) {
    max-width: 800px;
  }

  img {
    object-fit: contain;
    padding: 10px;
    width: 30%;
    height: auto;
    fill: var(--color2);
    color: var(--color2);
  }
`;

const TextZone = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  width: 60%;
  color: white;

  @media only screen and (min-width: 768px) {
    h1 {
      font-size: 1.2rem;
      font-weight: bold;
      margin: 0.2rem 0 0.5rem 0;
    }
    h2 {
      font-size: 1rem;
    }
    p {
      font-size: 0.9rem;
      text-align: justify;
    }

    a {
      color: white;
      font-size: 1rem;
      line-height: 1.1rem;
      padding: 0.5rem;
      margin: 0.5rem 0.5rem 0.1rem 0.5rem;
      background-color: var(--color2);
      cursor: pointer;
    }
  }

  h1 {
    font-size: 1rem;
    font-weight: bold;
    margin: 0.2rem 0 0.5rem 0;
  }
  h2 {
    font-size: 0.9rem;
    margin: 0.2rem 0 0.5rem 0;
  }
  p {
    font-size: 0.8rem;
    text-align: justify;
  }

  a {
    color: white;
    font-size: 0.7rem;
    line-height: 1.1rem;
    padding: 0.5rem;
    margin: 0.5rem 0.5rem 0.1rem 0.5rem;
    background-color: var(--color2);
    cursor: pointer;
  }

  span {
    display: inline-block;
  }
`;

const IndexPage = () => {
  const [itemSel, setItemSel] = useState(-1);

  return (
    <TemplatePage1
      title={"Cursos cortos"}
      hero={false}
      content={
        <Wrapper>
          {Courses["data"].length >= 1 && itemSel < 0 ? (
            <CardContainer>
              {Courses["data"].map((item, itemNumber) => (
                <Card key={"webinar_list" + item.id}>
                  <img src={item.image} alt={item.image} />
                  <TextZone>
                    <h1>{item.title ? item.title : ""}</h1>
                    <p>{item.description}</p>
                    <TitleEng>{item.titleeng ? item.titleeng : ""}</TitleEng>
                    {item.proffesorId.length > 0 && <h2>A cargo de:</h2>}
                    {item.proffesorId.map(
                      (proffesorIdNumber, proffesorKeyNumber) => (
                        <span>{People["data"][proffesorIdNumber].name}</span>
                      )
                    )}
                    <br />
                    <p>{item.startDate ? `Apertura: ${item.startDate}` : ""}</p>
                    <p>{item.endDate ? `Cierre: ${item.endDate}` : ""}</p>
                    <br />
                    <Button
                      bg={"var(--color2)"}
                      onClick={() => {
                        setItemSel(itemNumber);
                        document.body.scrollTop = 0;
                        document.documentElement.scrollTop = 0;
                      }}
                    >
                      Más información
                    </Button>
                  </TextZone>
                </Card>
              ))}
            </CardContainer>
          ) : Courses["data"].length >= 1 ? null : (
            "Proximamente se publicarán los webinars."
          )}
          {itemSel >= 0 ? (
            <CourseModalFader>
              <CourseModal>
                <h1>{Courses["data"][itemSel].title}</h1>
                <TitleEng>
                  {Courses["data"][itemSel].titleeng
                    ? Courses["data"][itemSel].titleeng
                    : ""}
                </TitleEng>
                {Courses["data"][itemSel].bannerImage ? (
                  <BannerImage
                    src={Courses["data"][itemSel].bannerImage}
                    alt={Courses["data"][itemSel].bannerImage}
                  />
                ) : null}

                {Courses["data"][itemSel].description ? (
                  <Subtitle>{Courses["data"][itemSel].description}</Subtitle>
                ) : null}

                {Courses["data"][itemSel].methodology &&
                  Courses["data"][itemSel].methodology.length > 0 && (
                    <ul style={{ padding: "2em" }}>
                      {Courses["data"][itemSel].methodology.map(
                        (item, index) => (
                          <li
                            key={`methodology_${index}${item}`}
                            style={{ listStyle: "circle" }}
                          >
                            {item}
                          </li>
                        )
                      )}
                    </ul>
                  )}

                <FaceCardHolder>
                  {Courses["data"][itemSel].proffesorId.map(
                    (proffesorIdNumber, proffesorKeyNumber) => (
                      <FaceCard>
                        <img
                          key={"professorImg" + proffesorKeyNumber}
                          src={PeoplePictureLib[proffesorIdNumber]}
                          alt={PeoplePictureLib[proffesorIdNumber]}
                        />
                        <div key={"professor" + proffesorKeyNumber}>
                          <h1>
                            {People["data"][proffesorIdNumber].name
                              ? People["data"][proffesorIdNumber].name
                              : ""}
                          </h1>
                          <h2>
                            {People["data"][proffesorIdNumber].title
                              ? People["data"][proffesorIdNumber].title
                              : ""}
                          </h2>
                          <h3>
                            {People["data"][proffesorIdNumber].description
                              ? People["data"][proffesorIdNumber].description
                              : ""}
                          </h3>
                        </div>
                      </FaceCard>
                    )
                  )}
                </FaceCardHolder>

                <CourseCard>
                  {Courses["data"][itemSel].language ? (
                    <MiniCard>
                      <img src={globe} alt={globe} />
                      Idioma: {Courses["data"][itemSel].language}
                    </MiniCard>
                  ) : null}
                  {Courses["data"][itemSel].startDate ||
                  Courses["data"][itemSel].endDate ? (
                    <MiniCard>
                      <img src={calendar} alt={calendar} />
                      <div className="text-sm">
                        <p>
                          {Courses["data"][itemSel].startDate
                            ? `Apertura: ${Courses["data"][itemSel].startDate}`
                            : ""}
                        </p>
                        <p>
                          {Courses["data"][itemSel].endDate
                            ? `Cierre: ${Courses["data"][itemSel].endDate}`
                            : ""}
                        </p>
                      </div>
                    </MiniCard>
                  ) : null}
                  {Courses["data"][itemSel].time ? (
                    <MiniCard>
                      <img src={watch} alt={watch} />
                      {Courses["data"][itemSel].time}
                    </MiniCard>
                  ) : null}

                  <ButtonZone>
                    {Courses["data"][itemSel].registerLink ? (
                      <Button bg={"var(--tema4)"}>
                        <a
                          href={Courses["data"][itemSel].registerLink}
                          target={"_blank"}
                          rel={"noreferrer"}
                        >
                          Registrarse
                        </a>
                      </Button>
                    ) : (
                      ""
                    )}
                    {Courses["data"][itemSel].seeVideoENG ? (
                      <Button bg={"var(--tema4)"}>
                        <a
                          href={Courses["data"][itemSel].seeVideoENG}
                          target={"_blank"}
                          rel={"noreferrer"}
                        >
                          See recording (Original Audio)
                        </a>
                      </Button>
                    ) : (
                      ""
                    )}
                    {Courses["data"][itemSel].seeVideoESP ? (
                      <Button bg={"var(--tema4)"}>
                        <a
                          href={Courses["data"][itemSel].seeVideoESP}
                          target={"_blank"}
                          rel={"noreferrer"}
                        >
                          Ver Grabación (Audio en Español)
                        </a>
                      </Button>
                    ) : (
                      ""
                    )}
                    {Courses["data"][itemSel].joinNow ? (
                      <Button bg={"var(--tema4)"}>
                        <Link to={Courses["data"][itemSel].joinNow}>Únase</Link>
                      </Button>
                    ) : (
                      ""
                    )}
                    {Courses["data"][itemSel].files ? (
                      <Button bg={"#928557"}>
                        <Link
                          target={"_blank"}
                          rel={"noreferrer"}
                          to={Courses["data"][itemSel].files}
                        >
                          Archivos
                        </Link>
                      </Button>
                    ) : (
                      ""
                    )}
                    <Button
                      bg={"var(--color2)"}
                      onClick={() => {
                        setItemSel(-1);
                      }}
                    >
                      <p style={{ width: "100%", "text-align": "center" }}>
                        Cerrar
                      </p>
                    </Button>
                  </ButtonZone>
                </CourseCard>
              </CourseModal>
            </CourseModalFader>
          ) : null}
        </Wrapper>
      }
    />
  );
};

export default IndexPage;

const CourseModalFader = styled.div`
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  background-color: var(--tema4);
  top: 0;
  left: 0;
  width: 100%;
  color: white;
  position: static;
  z-index: 99;
  overflow-y: scroll;
  @media only screen and (min-width: 768px) {
    position: static;
    overflow-y: visible;
  }
`;

const TitleEng = styled.h1`
  font-style: italic;
`;

const FaceCardHolder = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  justify-items: center;
  max-width: 900px;
  @media only screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

const CourseModal = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: static;
  background-color: #8697ad;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  color: white;
  h1 {
    font-size: 1.7rem;
    text-align: center;
  }
`;

const FaceCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
  text-align: left;
  @media only screen and (min-width: 768px) {
    width: 80%;
  }
  max-width: 400px;
  img {
    padding: 20px;
    width: 40%;
    max-width: 150px;
    max-height: 150px;
    border-radius: 50%;
    aspect-ratio: 1/1;
    object-fit: cover;
  }
  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    h1 {
      font-size: 1rem;
      text-align: left;
    }
    h2 {
      font-size: 0.9rem;
      text-align: left;
    }
    h3 {
      font-size: 0.8rem;
      text-align: left;
    }
  }
`;

const CourseCard = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 600px;
`;

const MiniCard = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 290px;
  font-size: 1.2rem;
  padding: 20px;
  img {
    padding: 10px;
    width: 40%;
    max-width: 60px;
  }
`;

const ButtonZone = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  max-width: 290px;
  font-size: 1rem;
  padding: 20px;
  img {
    padding: 10px;
    width: 40%;
    max-width: 60px;
  }
`;

const Button = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: center;
  text-align: center;
  color: white;
  margin: 3px;
  padding: 5px;
  font-size: 14px;
  background-color: ${(props) => (props.bg ? props.bg : "")};
  cursor: pointer;
  a {
    width: 100%;
  }
`;

const Subtitle = styled.h2`
  margin: 2rem auto;
  text-align: justify;
`;

const BannerImage = styled.img`
  margin: 2rem auto;
  width: 60%;
  min-width: 240px;
  max-width: 600px;
`;
